import URI from 'urijs';

const defaultConfigs = {
  scopes: [
    'openid',
    'athena/user/Identity.Demographics.read',
    'athena/user/Identity.Account.Factors.write',
    'athena/user/Identity.Account.Invites.write',
    'athena/user/Identity.Account.Profile.write',
    'athena/user/Identity.Account.Profile.read',
    'athena/user/Identity.LwaConfig.PostAuthn.read',
    'athena/user/Identity.Account.Profile.delete',
  ],
  redirectUri: 'oauth2/v1/login_callback_lwa_spa',
  loginThrottleLimits: [ [ 5, 60 ] ],
  anetUserDomain: 'managedidentity.platform.athenahealth.com',
  logoUrl: process.env.REACT_APP_LOGO_URL,
  oktaIdpDiscovery: false,
  fhirScopes: [ 'launch', 'launch/patient', 'launch/encounter' ],
  fhirEhrLaunchScopes: [ 'launch' ],
  useProfileManagementApp: process.env.REACT_APP_USE_PROFILE_MANAGEMENT_APP || false,
};

const nonProdAmplitudeApiKey = '20dc2a80bf07774d9fc999f614e9ea5e';
const prodAmplitudeApiKey = 'c41bf44737b4598dcae4c0eaec9b5833';

const defaultOktaUrl = process.env.REACT_APP_OKTA_URL || 'https://identity.dev.athenahealth.com/';
const defaultAuthServerBaseUrl = new URI(defaultOktaUrl).segment('oauth2')
  .toString();

const localDevConfig = {
  primaryAuthorizationServer: {
    baseUrl: defaultAuthServerBaseUrl,
    id: process.env.REACT_APP_OKTA_AUTHSERVER_ID || 'aus13kbtj4HAdI3451t7',
  },
  oauthClientId: process.env.REACT_APP_OAUTH_CLIENT_ID || '0oamlfrp9h2Hau1At1t7',
  oktaURL: defaultOktaUrl,
  oktaOrgId: process.env.REACT_APP_OKTA_ORG_ID || '00oot8nf9c7ovmdGX1t6',
  iamCoreUrl: process.env.REACT_APP_IAM_CORE_SERVER_URL || 'http://localhost:20020/',
  amplitudeApiKey: nonProdAmplitudeApiKey,
  loggingService: 'http://localhost:8080/api/v1/local/lwa-logger',
  // loginThrottleLimits - Array of arrays where the first number is the max number of logins to allow
  // and the second number is the time frame in seconds to enforce that
  // below commented out value left as an example how you could have multiple rules
  // [ [2, 60], [5, 180] ],
  loginThrottleLimits: [ [ 5, 60 ] ],
  manageProfileAppUrl: 'https://lwa-user-profile-management.athenahealth.com:33000/',
  ...defaultConfigs,
};

const awsDevConfig = {
  primaryAuthorizationServer: {
    baseUrl: 'https://identity.dev.athenahealth.com/oauth2/',
    id: 'aus13kbtj4HAdI3451t7',
  },
  oauthClientId: '0oamlfrp9h2Hau1At1t7',
  oktaURL: 'https://identity.dev.athenahealth.com/',
  oktaOrgId: '00oot8nf9c7ovmdGX1t6',
  iamCoreUrl: 'https://identity.dev.athena.io/identity/',
  amplitudeApiKey: nonProdAmplitudeApiKey,
  loggingService: 'https://identity.dev.athena.io/uiam-metrics/api/v1/dev/lwa-logger',
  manageProfileAppUrl: 'https://myidentity-dev-profile.platform.athenahealth.com',
};

const intConfig = {
  primaryAuthorizationServer: {
    baseUrl: 'https://identity.int.athenahealth.com/oauth2/',
    id: 'ausc16vzbfqYFn4jP0h7',
  },
  oauthClientId: '0oa16hdllg0O5yeW10h8',
  oktaURL: 'https://identity.int.athenahealth.com/',
  oktaOrgId: '00o9o9irbsIlOt9Oj0h7',
  iamCoreUrl: 'https://identity.int.athena.io/identity/',
  amplitudeApiKey: nonProdAmplitudeApiKey,
  loggingService: 'https://identity.int.athena.io/uiam-metrics/api/v1/int/lwa-logger',
  manageProfileAppUrl: 'https://myidentity-int-profile.platform.athenahealth.com',
  useProfileManagementApp: true,
};
const prodPrimaryAuthServer = {
  baseUrl: 'https://identity.athenahealth.com/oauth2/',
  id: 'auset0ja9xZ2Hniep296',
};
const prodConfig = {
  primaryAuthorizationServer: prodPrimaryAuthServer,
  oauthClientId: '0oacd3l1oiWKHn4mh297',
  oktaURL: 'https://identity.athenahealth.com/',
  oktaOrgId: '00o6wzsavf7U5zl4S296',
  iamCoreUrl: 'https://identity.athena.io/identity/',
  amplitudeApiKey: prodAmplitudeApiKey,
  loggingService: 'https://identity.athena.io/uiam-metrics/api/v1/prod/lwa-logger',
  manageProfileAppUrl: 'https://myidentity-profile.platform.athenahealth.com',
  useProfileManagementApp: true,
};

const supportPhiConfig = {
  primaryAuthorizationServer: {
    baseUrl: 'https://identity.athenahealth.com/oauth2/',
    id: 'ausu5cwvrZnWYasjS296',
  },
  oauthClientId: '0oaev3qq897XFntiv297',
  oktaURL: 'https://identity.athenahealth.com/',
  oktaOrgId: '00o6wzsavf7U5zl4S296',
  iamCoreUrl: 'https://identity.preview.api.athena.io/identity/',
  amplitudeApiKey: prodAmplitudeApiKey,
  loggingService: 'https://identity.athena.io/uiam-metrics/api/v1/support-phi/lwa-logger',
  manageProfileAppUrl: 'https://myidentity-support-profile.platform.athenahealth.com',
};

const supportNonPhiConfig = {
  primaryAuthorizationServer: {
    baseUrl: 'https://identity.athenahealth.com/oauth2/',
    id: 'aus1eumlr5MD3rYcn297',
  },
  oauthClientId: '0oaev3rzoihclvFKZ297',
  oktaURL: 'https://identity.athenahealth.com/',
  oktaOrgId: '00o6wzsavf7U5zl4S296',
  iamCoreUrl: 'https://identity.salesdemo.api.athena.io/identity/',
  amplitudeApiKey: prodAmplitudeApiKey,
  loggingService: 'https://identity.athena.io/uiam-metrics/api/v1/support-non-phi/lwa-logger',
  manageProfileAppUrl: 'https://myidentity-support-demo-profile.platform.athenahealth.com',
};

const envConfigMappings = {
  'http://localhost:3000': localDevConfig,
  'https://myidentity-dev.aws.athenahealth.com': awsDevConfig,
  'https://myidentity-int.aws.athenahealth.com': intConfig,
  'https://myidentity.platform.athenahealth.com': prodConfig,
  'https://myidentity-support.platform.athenahealth.com': supportPhiConfig,
  'https://myidentity-support-demo.platform.athenahealth.com': supportNonPhiConfig,
  'https://login.dev.platform.athenahealth.com:3000': localDevConfig,
};

export const envConfig = Object.keys(envConfigMappings).reduce((config, key) => {
  config[key] = { ...defaultConfigs, ...envConfigMappings[key] };
  return config;
}, {});

const config = envConfig[window.location.origin] || localDevConfig;
export default config;
