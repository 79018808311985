function getUserNamePrefix(username) {
  const found = username.toLowerCase().match(/[a-z]*-/);
  return (found && found.length) ? found[0] : '';
}

export const getAnetUserDomain = (username, options) => {
  const usernamePrefix = getUserNamePrefix(username);
  const { prefix, suffix } = options.userNamespace || {};
  if (prefix) {
    if (usernamePrefix === prefix) {
      return suffix;
    }
  }
  return '@' + options.anetUserDomain;
};

export const transformUsername = function (options, username, operation) {
  const { userNamespace } = options;
  let userSuffix = (userNamespace && userNamespace.suffix) ? userNamespace.suffix : '';
  // Ensure we don't end up appending `undefined` to username,
  // and it's expected to be set only for patient fuzzy users
  userSuffix = username.endsWith(userSuffix) ? '' : userSuffix;

  return username.indexOf('@') > -1
    ? `${username}${userSuffix}`
    : username + getAnetUserDomain(username, options);
};


export function isUserMismatchError(containerData) {
  return containerData.userAppTypeMismatchError || containerData.emailTokenLoggedInUserMismatchError;
}
